module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Robert Biernacki - Leczenie Kregosłupa","short_name":"starter","start_url":"/","background_color":"rgb(0, 82, 128)","display":"minimal-ui","icon":"src/images/logo.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"e3032500432b08c17fe9fce6e4411452"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
